import React from 'react'
import { graphql } from 'gatsby'
import GraphQLErrorList from '../components/graphql-error-list'
import BlockContent from '../components/block-content'
import SEO from '../components/seo'
import Img from 'gatsby-image'
import BackgroundImage from 'gatsby-background-image'
import Layout from '../containers/layout'

export const query = graphql`
  query BackgroundPageQuery {

    start: sanityBackgrounds {
      startPage {
        _key
        _type
        alt
        asset {
          fluid {
            base64
            aspectRatio
            src
            srcSet
            srcWebp
            srcSetWebp
            sizes
          }
        }
      }
    }

    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }

    pagecontent: sanityHome(_id: { regex: "/(drafts.|)/" }) {
      id
      title1
      title2
      title3
      _rawPortablebody
    }

  }
`

const BackgroundPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const start = (data || {}).start
  const site = (data || {}).site

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio and add some content to "Site settings" and restart the development server.'
    )
  }

  const pagecontent = data && data.pagecontent

  if (!pagecontent) {
    throw new Error(
      'Missing "Home" page data. Open the studio and add "Home" page data and restart the development server.'
    )
  }

  return (
    <Layout pageLayout='normal-footer'>
      <SEO title={site.title} description={site.description} keywords={site.keywords} />
      <article className='sections'>
        <section className='page-section layout-engine-section background-width--full-bleed section-height--large content-width--wide horizontal-alignment--center vertical-alignment--middle has-background white start'>

          <div className='mainImage hero--home hero'>
            <BackgroundImage
              Tag='section'
              style={{
                backgroundSize: 'cover',
                backgroundPosition: 'top center',
                backgroundRepeat: 'none',
                display: 'block',
                position: 'fixed',
                height: '100%',
                width: '100%',
                willChange: 'transform'
              }}
              fluid={start.startPage.asset.fluid}
              backgroundColor={`#040e18`}
            >
              <div className='content-wrapper start-page'>
                <div className='content'>
                  <h2>{pagecontent.title1}</h2>
                  <h2>{pagecontent.title2}</h2>
                  <h2>{pagecontent.title3}</h2>

                  <div className='hero-paragraph'>
                    {pagecontent._rawPortablebody && <BlockContent blocks={pagecontent._rawPortablebody || []} />}

                  </div>
                </div>
              </div>
            </BackgroundImage>
          </div>

        </section>
      </article>
    </Layout>
  )
}

export default BackgroundPage
